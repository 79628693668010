import React from 'react';
import ExperienciasComponent from '../../components/ExperienciasComponent';

const ExperienciaPage = () => {
    return (
        <div>
            <ExperienciasComponent />
        </div>
    );
};

export default ExperienciaPage;
